// @flow

/**
 * Module dependencies.
 */

import { Container, Fill } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import BackgroundImage from 'components/core/images/background-image';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundFluidImage: Object,
  description: string,
  title: string
|};

/**
 * `HeaderSection` styled component.
 */

const HeaderSection = styled.header`
  min-height: ${units(84)};
  padding-top: ${units(26)};
  position: relative;
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding-bottom: ${units(18)};
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { backgroundFluidImage, description, title } = props;

  return (
    <HeaderSection>
      <Fill>
        <StyledBackgroundImage fluid={backgroundFluidImage} />
      </Fill>

      <StyledContainer>
        {title && (
          <Type.H1
            color={color('secondary')}
            marginBottom={units(2)}
            textAlign={'center'}
          >
            {title}
          </Type.H1>
        )}

        {description && (
          <Type.H5
            as={'h2'}
            color={color('secondary')}
            margin={'0 auto'}
            maxWidth={units(43)}
            textAlign={'center'}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H5>
        )}
      </StyledContainer>
    </HeaderSection>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
