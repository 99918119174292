// @flow

/**
 * Module dependencies.
 */

import { type File } from 'types/file';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp } from 'styled-tools';
import Button from 'components/core/button';
import Image from 'components/core/images/image';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  items: Array<{|
    buttonLabel?: string,
    buttonUrl?: string,
    description: string,
    image: File,
    title: string
  |}>,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('grey100')};
  padding: ${units(14)} ${units(2)} ${units(5)};
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(4)};
  grid-template-areas:
    'information'
    'image';

  &:not(:last-child) {
    margin-bottom: ${units(10)};
  }

  ${media.min('md')`
    grid-gap: 0 ${units(4)};
    grid-template-areas: 'image information';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);

    ${ifProp('isDivisible', css`
      grid-template-areas: 'information image';
    `)}
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  grid-area: image;
  max-width: ${units(85)};
  width: 100%;

  ${media.min('xxl')`
    justify-self: end;

    ${ifProp('isDivisible', css`
      justify-self: start;
    `)}
  `}
`;

/**
 * `InformationWrapper` styled component.
 */

const InformationWrapper = styled.div`
  grid-area: information;
  max-width: ${units(55.5)};

  ${media.min('md')`
    margin: 0 auto;
  `}

  ${media.min('lg')`
    margin-right: 0;
    margin-left: ${units(15)};

    ${ifProp('isDivisible', css`
      justify-self: end;
      margin-left: 0;
      margin-right: ${units(15)};
    `)}
  `}
`;

/**
 * `DesktopButton` styled component.
 */

const DesktopButton = styled(Button)`
  display: none;

  ${media.min('md')`
    display: inline-flex;
    margin-top: ${units(3)};
    padding: 8px 12px;
    text-transform: initial;
  `}
`;

/**
 * `MobileButton` styled component.
 */

const MobileButton = styled(Button)`
  padding: 8px 12px;
  text-transform: initial;

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `Partners` component.
 */

function Partners({ items, title }: Props): Node {
  return (
    <Wrapper>
      <Type.H1
        color={color('secondary')}
        marginBottom={units(10)}
        textAlign={'center'}
      >
        {title}
      </Type.H1>

      {items.map((item, index) => {
        const { buttonLabel, buttonUrl, description, image, title } = item;
        const isDivisible = (index + 1) % 2 === 0;
        const hasButton = buttonLabel && buttonUrl;

        return (
          <Content
            isDivisible={isDivisible}
            key={index}
          >
            <StyledImage
              fluid={image.file.childImageSharp.fluid}
              isDivisible={isDivisible}
            />

            {hasButton && (
              <MobileButton
                colorTheme={'primary'}
                href={buttonUrl}
                rel={'noopener'}
                size={'small'}
                target={'_blank'}
              >
                {buttonLabel}
              </MobileButton>
            )}

            <InformationWrapper isDivisible={isDivisible}>
              <Type.H2
                color={color('secondary')}
                marginBottom={units(2)}
              >
                {title}
              </Type.H2>

              <Type.Label
                as={'div'}
                color={color('grey800')}
              >
                <RawHtml>
                  {description}
                </RawHtml>
              </Type.Label>

              {hasButton && (
                <DesktopButton
                  colorTheme={'primary'}
                  href={buttonUrl}
                  rel={'noopener'}
                  size={'small'}
                  target={'_blank'}
                >
                  {buttonLabel}
                </DesktopButton>
              )}
            </InformationWrapper>
          </Content>
        );
      })}
    </Wrapper>
  );
}

/**
 * Export `Partners` component.
 */

export default Partners;
