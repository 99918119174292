// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import FeaturesList, {
  type FeaturesListProps
} from 'components/features-list';

import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  ...FeaturesListProps,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(18)} ${units(3)};
  text-align: center;

  ${media.min('xl')`
    padding-left: ${units(15)};
    padding-right: ${units(15)};
  `}
`;

/**
 * `Offers` component.
 */

function Offers({ items, title }: Props): Node {
  return (
    <Wrapper>
      <RawHtml
        color={color('blue1000')}
        element={Type.H3}
        marginBottom={units(9)}
      >
        {title}
      </RawHtml>

      <FeaturesList items={items} />
    </Wrapper>
  );
}

/**
 * Export `Offers` component.
 */

export default Offers;
