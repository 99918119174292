// @flow

/**
 * Module dependencies.
 */

import type { TemplateProps } from 'types/template';
import { graphql, useStaticQuery } from 'gatsby';
import Assets from 'components/api/assets';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/api/header';
import Offers from 'components/api/offers';
import PageContainer from 'components/page-container';
import Partners from 'components/api/partners';
import Pricing from 'components/api/pricing';
import React, { type Node, useMemo } from 'react';

/**
 * API page query.
 */

const apiPageQuery = graphql`
  query {
    data: allSeegnoCmsApiPage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            title
            description
          }
          assets {
            list {
              image {
                file {
                  childImageSharp {
                    ...ResponsiveImage
                  }
                }
              }
              title
              description
            }
          }
          offers {
            title
            list {
              image {
                file {
                  publicURL
                }
              }
              title
              description
            }
          }
          partners {
            title
            list {
              image {
                file {
                  childImageSharp {
                    ...ResponsiveImage
                  }
                }
              }
              title
              description
              buttonLabel
              buttonUrl
            }
          }
          pricing {
            title
            description
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            list {
              backgroundColor
              icon {
                file {
                  publicURL
                }
              }
              isTawkTo
              title
              price {
                billedPriceFirstOption
                billedPriceFirstOptionLabel
                billedPriceSecondOption
                billedPriceSecondOptionLabel
              }
              usersLabel
              usersNumber
              buttonLabel
              buttonUrl
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
  }
`;

/**
 * `API` template.
 */

function API({ pageContext }: TemplateProps): Node {
  const { content, metatags } = pageContext;
  const { data } = useStaticQuery(apiPageQuery) ?? {};
  const dataContent = data?.nodes[0]?.content;
  const offers = useMemo(() => dataContent?.offers?.list.map(
    item => ({ ...item, image: item?.image?.file?.publicURL })
  ), [dataContent]);

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={dataContent?.header?.backgroundImage?.file?.childImageSharp?.fluid}
        description={content?.header?.description}
        title={content?.header?.title}
      />

      <Assets items={dataContent?.assets?.list} />

      <Offers
        items={offers}
        title={content?.offers?.title}
      />

      <Partners
        items={dataContent?.partners?.list}
        title={content?.partners?.title}
      />

      <Pricing data={dataContent?.pricing} />
    </PageContainer>
  );
}

/**
 * Export `API` template
 */

export default API;
