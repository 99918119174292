// @flow

/**
 * Module dependencies.
 */

import { type File } from 'types/file';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp } from 'styled-tools';
import Image from 'components/core/images/image';
import PricingCard from 'components/pricing-card';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  data: {|
    description: string,
    image: File,
    list: Array<{|
      backgroundColor: string,
      buttonLabel?: string,
      buttonUrl?: string,
      icon: {|
        file: {|
          publicURL: string
        |}
      |},
      isTawkTo?: boolean,
      price?: {|
        billedPriceFirstOption: string,
        billedPriceFirstOptionLabel?: string,
        billedPriceSecondOption?: string,
        billedPriceSecondOptionLabel?: string
      |},
      title: string,
      usersLabel: string,
      usersNumber: string
    |}>,
    title: string
  |}
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(20)} ${units(3)};

  ${media.min('xl')`
    padding-left: ${units(15)};
    padding-right: ${units(15)};
  `}
`;

/**
 * `ApiPricingContent` styled component.
 */

const ApiPricingContent = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5)};
  margin-bottom: ${units(16)};

  ${media.min('md')`
    grid-column-gap: ${units(5)};
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('lg')`
    grid-column-gap: ${units(20)};
  `}
`;

/**
 * `Information` styled component.
 */

const Information = styled.div`
  max-width: ${units(39)};

  ${media.min('md')`
    justify-self: end;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  max-width: ${units(81)};
  width: 100%;
`;

/**
 * `CardsWrapper` styled component.
 */

const CardsWrapper = styled.ul`
  display: grid;
  grid-row-gap: ${units(4)};

  ${media.min('ms')`
    grid-column-gap: ${units(4)};
    grid-template-columns: repeat(2, ${units(41)});
    justify-content: center;
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(3, ${units(41)});
  `}

  ${media.min('xxl')`
    grid-column-gap: ${units(11)};
  `}
`;

/**
 * `CardItem` styled component.
 */

const CardItem = styled.li`
  ${ifProp('isDivisible', css`
    ${media.min('ms')`
      grid-column: span 2;
    `}

    ${media.min('lg')`
      grid-column: span 1;
    `}
  `)}
`;

/**
 * `StyledPricingCard` styled component.
 */

const StyledPricingCard = styled(PricingCard)`
  max-width: ${units(41)};
`;

/**
 * `Pricing` component.
 */

function Pricing({ data }: Props): Node {
  const { description, image, list: items, title } = data;

  return (
    <Wrapper>
      <ApiPricingContent>
        <Information>
          <Type.H2
            color={color('secondary')}
            marginBottom={units(2)}
          >
            {title}
          </Type.H2>

          <Type.Label
            as={'div'}
            color={color('grey800')}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.Label>
        </Information>

        <StyledImage fluid={image.file.childImageSharp.fluid} />
      </ApiPricingContent>

      <CardsWrapper>
        {items.map((item, index) => {
          const isLast = index + 1 === items.length;
          const isDivisible = isLast && index % 2 === 0;

          return (
            <CardItem
              isDivisible={isDivisible}
              key={index}
            >
              <StyledPricingCard
                {...item}
                accent={item.backgroundColor}
                numbersLabel={item.usersLabel}
                numbersValue={item.usersNumber}
              />
            </CardItem>
          );
        })}
      </CardsWrapper>
    </Wrapper>
  );
}

/**
 * Export `Pricing` component.
 */

export default Pricing;
