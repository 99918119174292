// @flow

/**
 * Module dependencies.
 */

import { type File } from 'types/file';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp } from 'styled-tools';
import Image from 'components/core/images/image';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  items: Array<{|
    description: string,
    image: File,
    title: string
  |}>
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-top: -${units(22)};
  padding: 0 ${units(3)};

  ${media.min('md')`
    padding-left: ${units(7.5)};
    padding-right: ${units(7.5)};
  `}

  ${media.min('lg')`
    padding-left: ${units(15)};
    padding-right: ${units(15)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(4)};
  grid-template-areas:
    'information'
    'image';

  &:not(:last-child) {
    margin-bottom: ${units(4)};
  }

  ${media.min('md')`
    grid-gap: 0 ${units(4)};
    grid-template-areas: 'image information';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);

    ${ifProp('isDivisible', css`
      grid-template-areas: 'information image';
    `)}
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  grid-area: image;
  max-width: ${units(70)};
  width: 100%;

  ${media.min('xxl')`
    justify-self: center;
  `}
`;

/**
 * `InformationWrapper` styled component.
 */

const InformationWrapper = styled.div`
  grid-area: information;
  max-width: ${units(55.5)};
  position: relative;

  ${media.min('md')`
    margin: 0 auto;
  `}

  ${media.min('xl')`
    margin-left: ${units(15)};
  `}

  ${media.min('xxl')`
    margin: 0 auto;
  `}
`;

/**
 * `Assets` component.
 */

function Assets({ items }: Props): Node {
  return (
    <Wrapper>
      {items.map(({ description, image, title }, index) => {
        const isDivisible = (index + 1) % 2 === 0;

        return (
          <Content
            isDivisible={isDivisible}
            key={index}
          >
            <StyledImage fluid={image.file.childImageSharp.fluid} />

            <InformationWrapper isDivisible={isDivisible}>
              <Type.H2
                color={color('secondary')}
                marginBottom={units(2)}
              >
                {title}
              </Type.H2>

              <Type.Label
                as={'div'}
                color={color('grey800')}
              >
                <RawHtml>
                  {description}
                </RawHtml>
              </Type.Label>
            </InformationWrapper>
          </Content>
        );
      })}
    </Wrapper>
  );
}

/**
 * Export `Assets` component.
 */

export default Assets;
